import Loadable from "app/components/Loadable";
import { lazy } from "react";
import ClubForm from "./forms/ClubForm";
import AddOnForm from "./forms/AddonForm";

import SubscriptionForm from "./forms/SubscriptionForm";
import ClubTable from "./tables/ClubTable";
import CouponTables from "./tables/CouponTables";

import SubscriptionTable from "./tables/SubscriptionTable";

import AddOnTable from "./tables/addonsTable";
import ProductThirdParty from "./tables/ProductThirdPart";
import AssignCardsClubs from "./tables/AssignCardClub";
import UserProfile from "./tables/UserProfile";

const AppTable = Loadable(lazy(() => import("./tables/AppTable")));
const ClientTable = Loadable(lazy(() => import("./tables/ClientTableList")));
const NotifcationForm = Loadable(lazy(() => import("./forms/NotifcationForm/NotificationForm")));
const SegmentTable = Loadable(lazy(() => import("./tables/SegmentTable")));
const TransactionTable = Loadable(lazy(() => import("./tables/TransactionTable")));
const CampaignTable = Loadable(lazy(() => import("./tables/CampaignTable")));
const CouponsForm = Loadable(lazy(() => import("./forms/CouponsVouchers/Index")));
const RewardTable = Loadable(lazy(() => import("./tables/RewardTable")));
const DefaultCardTable = Loadable(lazy(() => import("./tables/DefaultLoyaltyCardTable")));
const ProductTable = Loadable(lazy(() => import("./tables/ProductTable")));
const AssignCards = Loadable(lazy(() => import("./forms/AssignCards")));

const AppForm = Loadable(lazy(() => import("./forms/AppForm")));
const CampaignForm = Loadable(lazy(() => import("./forms/CampaignForm/CampaignForm")));
const RewardForm = Loadable(lazy(() => import("./forms/NewRewardsForm")));
const ProductForm = Loadable(lazy(() => import("./forms/ProductForm")));

const AppButton = Loadable(lazy(() => import("./buttons/AppButton")));
const AppIcon = Loadable(lazy(() => import("./icons/AppIcon")));
const AppProgress = Loadable(lazy(() => import("./AppProgress")));
const AppMenu = Loadable(lazy(() => import("./menu/AppMenu")));
const AppCheckbox = Loadable(lazy(() => import("./checkbox/AppCheckbox")));
const AppSwitch = Loadable(lazy(() => import("./switch/AppSwitch")));
const AppRadio = Loadable(lazy(() => import("./radio/AppRadio")));
const AppSlider = Loadable(lazy(() => import("./slider/AppSlider")));
const AppDialog = Loadable(lazy(() => import("./dialog/AppDialog")));
const AppSnackbar = Loadable(lazy(() => import("./snackbar/AppSnackbar")));
const AppAutoComplete = Loadable(lazy(() => import("./auto-complete/AppAutoComplete")));
const AppExpansionPanel = Loadable(lazy(() => import("./expansion-panel/AppExpansionPanel")));
var role = localStorage.getItem("userRole");

function getClubId() {
  return localStorage.getItem("clubId");
}
const clubId = getClubId();
const materialRoutes = [
  {
    path: "/table",
    element: <AppTable />
  },
  {
    path: "/clients",
    element: <ClientTable />
  },
  {
    path: "/segments",
    element: <SegmentTable />
  },
  {
    path: "/transactions",
    element: <TransactionTable />
  },
  {
    path: "/campaign",
    element: <CampaignTable />
  },
  {
    path: "/couponsVoucher",
    element: <CouponsForm />
  },
  {
    path: "/notification",
    element: <NotifcationForm />
  },
  {
    path: "/rewards",
    element: <RewardTable />
  },
  {
    path: "/default-card",
    element: <DefaultCardTable />
  },
  {
    path: "/products",
    element: <ProductTable />
  },
  {
    path: "/assign-card/:id",
    element: <AssignCards />
  },
  {
    path: "/assign-card-club/:id/:clubId",
    element: <AssignCardsClubs />
  },
  {
    path: "/edit-card/:id",
    element: <RewardForm />
  },
  {
    path: "/form",
    element: <AppForm />
  },
  {
    path: "/form/campaign",
    element: <CampaignForm />
  },
  {
    path: "/form/reward",
    element: <RewardForm />
  },
  {
    path: "/form/product",
    element: <ProductForm />
  },
  {
    path: "/form/club",
    element: <ClubForm />
  },
  {
    path: "/form/add-on",
    element: <AddOnForm />
  },
  {
    path: "/form/subscription",
    element: <SubscriptionForm />
  },
  {
    path: "/buttons",
    element: <AppButton />
  },
  {
    path: "/icons",
    element: <AppIcon />
  },
  {
    path: "/progress",
    element: <AppProgress />
  },
  {
    path: "/menu",
    element: <AppMenu />
  },
  {
    path: "/checkbox",
    element: <AppCheckbox />
  },
  {
    path: "/switch",
    element: <AppSwitch />
  },
  {
    path: "/radio",
    element: <AppRadio />
  },
  {
    path: "/slider",
    element: <AppSlider />
  },
  {
    path: "/autocomplete",
    element: <AppAutoComplete />
  },
  {
    path: "/expansion-panel",
    element: <AppExpansionPanel />
  },
  {
    path: "/dialog",
    element: <AppDialog />
  },
  {
    path: "/snackbar",
    element: <AppSnackbar />
  },
  {
    path: "/third-party-products",
    element: <ProductThirdParty />
  }
];
if (!clubId) {
  materialRoutes.push(
    {
      path: "/subscriptions",
      element: <SubscriptionTable />
    },
    {
      path: "/add-ons",
      element: <AddOnTable />
    },
    {
      path: "/clubs",
      element: <ClubTable />
    },
    {
      path: "/coupons",
      element: <CouponTables />
    },
    {
      path: "/user-profile",
      element: <UserProfile />
    }
  );
}

export default materialRoutes;
