import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled
} from "@mui/material";
import { Span } from "app/components/Typography";
import axios from "axios";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useParams } from "react-router-dom";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px"
}));

const ManagerForm = () => {
  const [state, setState] = useState({ products: [], date: new Date() });
  const [productList, setProductList] = useState([]);
  let { clubId } = useParams();

  const configs = {
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== state.password) return false;

      return true;
    });
    return () => ValidatorForm.removeValidationRule("isPasswordMatch");
  }, [state.password]);

  ValidatorForm.addValidationRule("isValidPassword", (value) => {
    // Check for minimum 8 characters, alphanumeric, and at least one special character
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\W_]).{8,}$/;
    return regex.test(value);
  });
  const handleSubmit = (event) => {
    var data = {
      username: "test",
      email,
      gender,
      dob: date,
      name: firstName,
      password,
      phone: mobile,
      userRole: "manager",
      clubId: clubId
    };
    axios
      .post("https://loyalty.xpcover.com/api/user/add", data, configs)
      .then((res) => alert("User Successfully Created!"));
    // console.log("submitted");
    // console.log(event);
  };

  const handleChange = (event) => {
    // event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => setState({ ...state, date });

  const { username, firstName, products, mobile, password, confirmPassword, gender, date, email } =
    state;

  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            {/* <TextField
              type="text"
              name="username"
              id="standard-basic"
              value={username || ""}
              onChange={handleChange}
              errorMessages={["this field is required"]}
              label="Username (Min length 4, Max length 9)"
              validators={["required", "minStringLength: 4", "maxStringLength: 9"]}
            /> */}

            <TextField
              type="text"
              name="firstName"
              label="Name"
              onChange={handleChange}
              value={firstName || ""}
              validators={["required"]}
              errorMessages={["this field is required"]}
            />

            <TextField
              type="email"
              name="email"
              label="Email"
              value={email || ""}
              onChange={handleChange}
              validators={["required", "isEmail"]}
              errorMessages={["this field is required", "email is not valid"]}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={date}
                onChange={handleDateChange}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Date Of Birth"
                    id="mui-pickers-date"
                    sx={{ mb: 2, width: "100%" }}
                  />
                )}
              />
            </LocalizationProvider>

            {/* <FormControl fullWidth>
              <InputLabel htmlFor="products">Cards</InputLabel>
              <Select multiple name="products" value={products} onChange={handleChange}>
                {productList.map((a) => (
                  <MenuItem value={a._id}>{a.name}</MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="mobile"
              value={mobile || ""}
              label="Mobile Nubmer"
              onChange={handleChange}
              validators={["required"]}
              errorMessages={["this field is required"]}
            />
            <TextField
              name="password"
              type="password"
              label="Password"
              value={password || ""}
              onChange={handleChange}
              validators={["required", "isValidPassword"]}
              errorMessages={[
                "this field is required",
                "Password must be at least 8 characters long, contain letters, numbers, and at least one special character"
              ]}
            />
            <TextField
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              label="Confirm Password"
              value={confirmPassword || ""}
              validators={["required", "isPasswordMatch"]}
              errorMessages={["this field is required", "password didn't match"]}
            />

            {/* <FormControlLabel
              control={<Checkbox />}
              label="I have read and agree to the terms of service."
            /> */}
          </Grid>
        </Grid>

        <Button color="primary" variant="contained" type="submit">
          <Icon>send</Icon>
          <Span sx={{ pl: 1, textTransform: "capitalize" }}>ADD MANAGER</Span>
        </Button>
      </ValidatorForm>
    </div>
  );
};

export default ManagerForm;
