import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } },
  },
}));

const subscribarList = [
  {
    name: 'john doe',
    date: '18 january, 2019',
    amount: 1000,
    status: 'close',
    company: 'ABC Fintech LTD.',
  },
  {
    name: 'kessy bryan',
    date: '10 january, 2019',
    amount: 9000,
    status: 'open',
    company: 'My Fintech LTD.',
  },
  {
    name: 'kessy bryan',
    date: '10 january, 2019',
    amount: 9000,
    status: 'open',
    company: 'My Fintech LTD.',
  },
  {
    name: 'james cassegne',
    date: '8 january, 2019',
    amount: 5000,
    status: 'close',
    company: 'Collboy Tech LTD.',
  },
  {
    name: 'lucy brown',
    date: '1 january, 2019',
    amount: 89000,
    status: 'open',
    company: 'ABC Fintech LTD.',
  },
  {
    name: 'lucy brown',
    date: '1 january, 2019',
    amount: 89000,
    status: 'open',
    company: 'ABC Fintech LTD.',
  },
  {
    name: 'lucy brown',
    date: '1 january, 2019',
    amount: 89000,
    status: 'open',
    company: 'ABC Fintech LTD.',
  },
  {
    name: 'lucy brown',
    date: '1 january, 2019',
    amount: 89000,
    status: 'open',
    company: 'ABC Fintech LTD.',
  },
  {
    name: 'lucy brown',
    date: '1 january, 2019',
    amount: 89000,
    status: 'open',
    company: 'ABC Fintech LTD.',
  },
];

const ClubTableList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [productList, setProductList] = useState([]);
  const history = useNavigate();

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const configs = {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  };
  useEffect(() => {
    axios
      .get('https://loyalty.xpcover.com/api/client/get-subscriptions', configs)
      .then((res) => setProductList(res.data.msg));
  }, []);
  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">maxMembers</TableCell>
            <TableCell align="center">maxPrepaidCards</TableCell>
            <TableCell align="center">validityDays</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((subscriber, index) => (
              <TableRow key={index}>
                <TableCell align="left">{subscriber.name}</TableCell>
                <TableCell align="center">{subscriber.maxMembers}</TableCell>
                <TableCell align="center">{subscriber.maxPrepaidCards}</TableCell>

                <TableCell align="center">{subscriber.validityDays}</TableCell>

                <TableCell align="right">
                  <IconButton onClick={() => history(`/edit-subscription/${subscriber.id}`)}>
                    <Icon color="primary">edit</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={subscribarList.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default ClubTableList;
