// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAfxFp2XTTOYvC_ROtJMHmikrKzjEwB1ik',
  authDomain: 'loyalty-app-ded1f.firebaseapp.com',
  projectId: 'loyalty-app-ded1f',
  storageBucket: 'loyalty-app-ded1f.appspot.com',
  messagingSenderId: '919402068596',
  appId: '1:919402068596:web:c8575cfb1e42a394112f21',
  measurementId: 'G-6KJMM3GK9X',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export default storage;
