import { Box, styled, Button } from '@mui/material';
import { SimpleCard } from 'app/components';
import PaginationTable from './PaginationTable';
import SimpleTable from './SimpleTable';
import { useNavigate } from 'react-router-dom';
import ClubTableList from './ClubTableList';
const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const ClubTable = () => {
  const history = useNavigate();

  return (
    <Container>
      <Box className="breadcrumb">
        <Button onClick={() => history('/form/club')} variant="contained" color="primary">
          Add Club
        </Button>
        {/* <Breadcrumb routeSegments={[{ name: 'Material', path: '/material' }, { name: 'Clubs' }]} /> */}
      </Box>

      {/* <SimpleCard title="List of member">
        <SimpleTable />
      </SimpleCard> */}

      <SimpleCard title="Clubs">
        <ClubTableList />
      </SimpleCard>
    </Container>
  );
};

export default ClubTable;
