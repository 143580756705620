import { Box, styled, Button } from "@mui/material";
import { SimpleCard } from "app/components";
import PaginationTable from "./PaginationTable";
import SimpleTable from "./SimpleTable";
import { useNavigate } from "react-router-dom";
import ClubTableList from "./ClubTableList";
import CouponTableList from "./CouponTable";
import { useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import { useState } from "react";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));

const UserProfile = () => {
  const history = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    var clientId = localStorage.getItem("clientId");
    axios
      .get(`https://loyalty.xpcover.com/api/client/get-by-id/${clientId}`)
      .then((res) => {
        const datas = res.data.msg[0];
        delete datas._id;

        delete datas.active;
        delete datas.salt;
        delete datas.hash;
        setData(datas);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  return (
    <Container>
      {/* <SimpleCard title="List of member">
        <SimpleTable />
      </SimpleCard> */}

      <SimpleCard title="Client Info">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ? Object.entries(data).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{String(value)}</TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>{" "}
      </SimpleCard>
    </Container>
  );
};

export default UserProfile;
