import { Button, FormControl, Icon, InputLabel, MenuItem, Select } from '@mui/material';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditClubForm from '../forms/EditClub';
import ManagerForm from '../forms/ManagerForm';
import { Tabs, Tab, Box, Typography } from '@mui/material';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const AssignCardsClubs = () => {
  let { id } = useParams();
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const [productList, setProductList] = useState([]);
  const [state, setState] = useState({ products: [], date: new Date() });
  const { products } = state;
  const configs = {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  };
  useEffect(() => {
    axios
      .get('https://loyalty.xpcover.com/api/card/get', configs)
      .then((res) => setProductList(res.data.msg));
  }, []);
  const handleChange = (event) => {
    // event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const handleSubmit = () => {
    if (products.length === 0) {
      alert('Please select the cards');
      return;
    }
    axios
      .post(
        'https://loyalty.xpcover.com/api/card/assign-card-club',
        { id: id, cards: products },
        configs
      )
      .then((res) => alert('Successfully Assigned'));
  };
  return (
    <div className="edit_user">
      <Box>
        {/* Tabs header */}
        <Tabs value={value} onChange={handleChangeTab} aria-label="simple tabs example">
          <Tab label="Edit Club" />
          <Tab label="Create Manager" />
          <Tab label="Assign Card" />
        </Tabs>

        {/* TabPanels */}
        <TabPanel value={value} index={0}>
          <EditClubForm />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ManagerForm />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SimpleCard title="Allot Card to Club">
            <FormControl fullWidth>
              <InputLabel htmlFor="products">Loyalty Cards</InputLabel>
              <Select multiple name="products" value={products} onChange={handleChange}>
                {productList.map((a) => (
                  <MenuItem value={a._id}>{a.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <br />
            <div className="assign_card_btn">
              <Button
                onClick={() => handleSubmit()}
                color="primary"
                variant="contained"
                type="submit"
              >
                <Icon>send</Icon>
                <Span sx={{ pl: 1, textTransform: 'capitalize' }}>Assign Cards</Span>
              </Button>
            </div>
          </SimpleCard>
        </TabPanel>
      </Box>
      {/* <div>
        <h2>Edit Club</h2>
        <EditClubForm />
      </div>
      <div>
        <h2>Create Manager</h2>
        <ManagerForm />
      </div> */}
    </div>
  );
};

export default AssignCardsClubs;
