import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
} from '@mui/material';
import { Span } from 'app/components/Typography';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const ClubForm = () => {
  const [state, setState] = useState({ date: new Date() });
  const [mainImage, setMainImage] = useState('');
  const [products, setProducts] = useState([]);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== state.password) return false;

      return true;
    });
    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [state.password]);

  const handleSubmit = (event) => {
    const configs = {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    };
    const subscriptionIds = products.map((a) => a._id);
    axios
      .post(
        `https://loyalty.xpcover.com/api/client/create-addon`,
        { ...state, subscriptionIds },
        configs
      )
      .then((res) => alert('addon Successfully Created!'));

    // console.log("submitted");
    // console.log(event);
  };

  const handleChange = (event) => {
    // event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => setState({ ...state, date });
  const onDrop = (acceptedFiles, index, additionalProp1) => {
    // Your onDrop logic here
    console.log('Additional Prop 1:', additionalProp1, index);

    // Rest of your code
    const newImages = [];
    newImages.push(URL.createObjectURL(acceptedFiles[0]));

    setMainImage(newImages);
  };

  const {
    name,
    maxMembers,
    maxClubs,
    maxPrepaidCards,
    maxOwnProducts,
    maxExternalProducts,
    product,
    active,
  } = state;
  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg, image/gif',
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024, // 2MB
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, 0), // Pass props for input 1
  });

  // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
  const configs = {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  };
  useEffect(() => {
    axios
      .get('https://loyalty.xpcover.com/api/client/get-subscriptions', configs)
      // .then((res) => console.log(res.data));
      .then((res) => setProductList(res.data.msg));
  }, []);

  return (
    <div>
      <Card style={{ padding: '50px' }}>
        <h3>Create Add On</h3>

        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
              <TextField
                type="text"
                name="name"
                id="standard-basic"
                value={name || ''}
                onChange={handleChange}
                errorMessages={['this field is required']}
                label="AddOn Name"
                validators={['required']}
              />

              <TextField
                type="number"
                name="maxMembers"
                label="Maximum Members"
                value={maxMembers || ''}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />

              <TextField
                type="number"
                name="maxClubs"
                value={maxClubs || ''}
                label="Maximum Clubs"
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />
              <TextField
                name="maxPrepaidCards"
                type="number"
                label="Maximum PrepaidCards"
                value={maxPrepaidCards || ''}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />

              <TextField
                name="maxOwnProducts"
                type="number"
                label="Maximum OwnProducts"
                value={maxOwnProducts || ''}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />
              <TextField
                name="maxExternalProducts"
                type="number"
                label="Maximum ExternalProducts"
                value={maxExternalProducts || ''}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />

              <FormControl fullWidth>
                <InputLabel htmlFor="product">Select Subscriptions</InputLabel>
                <Select
                  multiple
                  name="product"
                  value={products}
                  onChange={(e) => setProducts(e.target.value)}
                >
                  {productList.map((a) => (
                    <MenuItem value={a}>{a.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Button style={{ margin: '30px' }} color="primary" variant="contained" type="submit">
            <Icon>send</Icon>
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>Create Addon</Span>
          </Button>
        </ValidatorForm>
      </Card>
    </div>
  );
};

export default ClubForm;
