import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled
} from "@mui/material";
import { Span } from "app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDropzone } from "react-dropzone";
import axios from "axios";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px"
}));

const ClubForm = () => {
  const [state, setState] = useState({ date: new Date() });
  const [mainImage, setMainImage] = useState("");

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== state.password) return false;

      return true;
    });
    return () => ValidatorForm.removeValidationRule("isPasswordMatch");
  }, [state.password]);
  // Custom validation rule
  ValidatorForm.addValidationRule("isValidPassword", (value) => {
    // Check for minimum 8 characters, alphanumeric, and at least one special character
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\W_]).{8,}$/;
    return regex.test(value);
  });
  const handleSubmit = (event) => {
    const configs = {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
    };
    axios
      .post(`https://loyalty.xpcover.com/api/club/create`, { ...state }, configs)
      .then((res) => alert("Club Successfully Created!"));

    // console.log("submitted");
    // console.log(event);
  };

  const handleChange = (event) => {
    // event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => setState({ ...state, date });
  const onDrop = (acceptedFiles, index, additionalProp1) => {
    // Your onDrop logic here
    console.log("Additional Prop 1:", additionalProp1, index);

    // Rest of your code
    const newImages = [];
    newImages.push(URL.createObjectURL(acceptedFiles[0]));

    setMainImage(newImages);
  };
  const {
    name,
    firstName,
    creditCard,
    currency,
    mobile,
    password,
    confirmPassword,
    gender,
    date,
    email
  } = state;
  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg, image/gif",
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024, // 2MB
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, 0) // Pass props for input 1
  });
  const currencyOptions = [
    "US Dollar (USD)",
    "Euro (EUR)",
    "British Pound (GBP)",
    "Japanese Yen (JPY)",
    "Canadian Dollar (CAD)",
    "Australian Dollar (AUD)",
    "Swiss Franc (CHF)",
    "Chinese Yuan (CNY)",
    "Swedish Krona (SEK)",
    "New Zealand Dollar (NZD)",
    "Indian Rupee (INR)" // Added Indian Rupee
    // Add more currencies as needed
  ];
  // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js

  return (
    <div>
      <Card style={{ padding: "25px" }}>
        <div>
          <h3>Main Image</h3>
          <div {...getRootProps1()} className="dropzone">
            <input {...getInputProps1()} />
            <div className="image-preview">
              {mainImage ? <img height="250px" src={mainImage} alt={`Image `} /> : null}
            </div>
            {mainImage ? <p>Drag 'n' drop an image here, or click to select an image</p> : null}
          </div>
        </div>
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
              <TextField
                type="text"
                name="name"
                id="standard-basic"
                value={name || ""}
                onChange={handleChange}
                errorMessages={["this field is required"]}
                label="name"
                validators={["required"]}
              />

              <TextField
                type="email"
                name="email"
                label="Email"
                value={email || ""}
                onChange={handleChange}
                validators={["required", "isEmail"]}
                errorMessages={["this field is required", "email is not valid"]}
              />

              <TextField
                type="text"
                name="mobile"
                value={mobile || ""}
                label="Mobile Nubmer"
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
              <TextField
                name="password"
                type="password"
                label="Password"
                value={password || ""}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
              <TextField
                type="password"
                name="confirmPassword"
                onChange={handleChange}
                label="Confirm Password"
                value={confirmPassword || ""}
                validators={["required", "isPasswordMatch", "isValidPassword"]}
                errorMessages={[
                  "this field is required",
                  "password didn't match",
                  "Password must be at least 8 characters long, contain letters, numbers, and at least one special character"
                ]}
              />

              <FormControl fullWidth>
                <InputLabel htmlFor="currency">Currency</InputLabel>
                <Select name="currency" value={currency} onChange={handleChange}>
                  {currencyOptions.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel control={<Checkbox />} label="Active" />
            </Grid>
          </Grid>

          <Button color="primary" variant="contained" type="submit">
            <Icon>send</Icon>
            <Span sx={{ pl: 1, textTransform: "capitalize" }}>ADD MEMBER</Span>
          </Button>
        </ValidatorForm>
      </Card>
    </div>
  );
};

export default ClubForm;
