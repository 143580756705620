import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.css";

import { MatxTheme } from "./components";
// ALL CONTEXTS
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
// ROUTES
import routes from "./routes";
// FAKE SERVER
import "../fake-db";
import { useEffect } from "react";

export default function App() {
  const content = useRoutes(routes);
  const navigate = useNavigate();

  useEffect(() => {
    var token = localStorage.getItem("accessToken");
    if (!token) navigate("/client/signin");
  }, []);
  return (
    <SettingsProvider>
      <AuthProvider>
        <MatxTheme>
          <CssBaseline />
          {content}
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  );
}
