import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
} from '@mui/material';
import storage from '../../../../firebase';
import { Span } from 'app/components/Typography';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const ClubForm = () => {
  const [state, setState] = useState({ date: new Date() });
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [percent, setPercent] = useState(0);

  const [mainImage, setMainImage] = useState('');

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== state.password) return false;

      return true;
    });
    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [state.password]);

  const handleSubmit = (event) => {
    const configs = {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    };
    axios
      .post(
        `https://loyalty.xpcover.com/api/client/create-subscription`,
        { ...state, icon: backgroundImageUrl },
        configs
      )
      .then((res) => alert('Subscription Successfully Created!'));

    // console.log("submitted");
    // console.log(event);
  };

  const handleChange = (event) => {
    // event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => setState({ ...state, date });

  const {
    name,
    price,
    maxMembers,
    maxClubs,
    maxPrepaidCards,
    maxOwnProducts,
    maxExternalProducts,
    validityDays,
    active,
  } = state;

  const onDrop = (acceptedFiles, index, additionalProp1) => {
    // Your onDrop logic here
    console.log('Additional Prop 1:', additionalProp1, index);

    // Rest of your code
    const newImages = [];
    uploadImageToFirebaseStorage(acceptedFiles[0]);
    newImages.push(URL.createObjectURL(acceptedFiles[0]));
    if (additionalProp1 === 'Main') {
      setMainImage(newImages);
    }
  };
  async function uploadImageToFirebaseStorage(file, additionalProp1) {
    if (!file) {
      alert('Please choose a file first!');
    }

    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setBackgroundImageUrl(url);
        });
      }
    );
  }
  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg, image/gif',
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024, // 2MB
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, 0), // Pass props for input 1
  });

  // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
  const inputProp1 = 'Main';

  return (
    <div>
      <Card style={{ padding: '50px' }}>
        <h3>Create Subscription</h3>

        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
              <div {...getRootProps1()} className="dropzone">
                <input {...getInputProps1()} inputprop={inputProp1} />
                <div className="image-preview">
                  {/* {<img height="250px" src={mainImage} alt={`Image `} />} */}
                </div>
                <p>Drag 'n' drop an image here, or click to select an image</p>
              </div>
              <TextField
                type="text"
                name="name"
                id="standard-basic"
                value={name || ''}
                onChange={handleChange}
                errorMessages={['this field is required']}
                label="Subscription Name"
                validators={['required']}
              />
              <TextField
                type="text"
                name="price"
                id="standard-basic"
                value={price || ''}
                onChange={handleChange}
                errorMessages={['this field is required']}
                label="Subscription price"
                validators={['required']}
              />

              <TextField
                type="number"
                name="maxMembers"
                label="Maximum Members"
                value={maxMembers || ''}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />

              <TextField
                type="number"
                name="maxClubs"
                value={maxClubs || ''}
                label="Maximum Clubs"
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />
              <TextField
                name="maxPrepaidCards"
                type="number"
                label="Maximum PrepaidCards"
                value={maxPrepaidCards || ''}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />

              <TextField
                name="maxOwnProducts"
                type="number"
                label="Maximum OwnProducts"
                value={maxOwnProducts || ''}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />
              <TextField
                name="maxExternalProducts"
                type="number"
                label="Maximum ExternalProducts"
                value={maxExternalProducts || ''}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />
              <TextField
                name="validityDays"
                type="number"
                label="Validity Days"
                value={validityDays || ''}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['this field is required']}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    errorMessages={['this field is required']}
                    validators={['required']}
                    value={active}
                    onChange={handleChange}
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>

          <Button style={{ margin: '30px' }} color="primary" variant="contained" type="submit">
            <Icon>send</Icon>
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>Create Subscription</Span>
          </Button>
        </ValidatorForm>
      </Card>
    </div>
  );
};

export default ClubForm;
